import { useState, useEffect, useRef } from 'react';
import { Group, Text, Rect } from 'react-konva';
import { animated } from '@react-spring/konva';
import { useSpring } from '@react-spring/web';
import { REACTION_EMOJIS } from '../../constants/emojis';
import { ReactBarParticles } from '../animations/ReactBarParticles'
import { createExplosion } from '../animations/animations';
import { getFullUrl } from '../../utils/utils';

// Create animated versions of Konva components
const AnimatedGroup = animated(Group);
const AnimatedRect = animated(Rect);

const ReactBar = ({ posterWidth, onSelectionChange, initialSelection, eventUrl, shareURL, retrievedReactionCounts }) => {
    const barWidth = 250;
    const xOffset = (posterWidth - barWidth) / 2;
    const [selectedIndex, setSelectedIndex] = useState(initialSelection);
    const [reactionCounts, setReactionCounts] = useState(retrievedReactionCounts);
    const [hasChanges, setHasChanges] = useState(false);
    const [particles, setParticles] = useState([]);
    const latestData = useRef({ reactionCounts, hasChanges });

    // Animation spring
    const [{ height, opacity }] = useSpring(() => ({
        from: { height: 0, opacity: 0 },
        to: { height: 40, opacity: 1 },
        config: {
            mass: 1.5,
            tension: 220,
            friction: 22
        },
        delay: 150,
    }));


    // Save changes when component unmounts
    useEffect(() => {
        latestData.current = { reactionCounts, hasChanges };
    }, [reactionCounts, hasChanges]);

    useEffect(() => {
        return () => {
            if (latestData.current.hasChanges && selectedIndex !== null) {
                console.log(`+1 to reaction ${selectedIndex}`);
            } else if (latestData.current.hasChanges && selectedIndex === null) {
                console.log(`-1 to reaction ${initialSelection}`);
            }
        };
    }, []);


    const handleReactBarClick = (index) => {
        const clickedLetter = REACTION_EMOJIS[index];

        // Batch all state updates together
        setReactionCounts(prevCounts => {
            const newCounts = { ...prevCounts };

            if (selectedIndex === index) {
                newCounts[clickedLetter] = Math.max(0, (newCounts[clickedLetter] || 0) - 1);
            } else {
                if (selectedIndex !== null) {
                    const prevLetter = REACTION_EMOJIS[selectedIndex];
                    newCounts[prevLetter] = Math.max(0, (newCounts[prevLetter] || 0) - 1);
                }
                newCounts[clickedLetter] = (newCounts[clickedLetter] || 0) + 1;
            }

            setHasChanges(true);
            return newCounts;
        });

        // Handle other state updates after setReactionCounts
        if (selectedIndex === index) {
            setSelectedIndex(null);
            onSelectionChange(null);
        } else {
            setSelectedIndex(index);
            onSelectionChange(index);
            const newParticles = createExplosion(index);
            setParticles(newParticles);
            setTimeout(() => setParticles([]), 1000);
        }
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                const shareData = {
                    title: 'Check out this poster!',
                    text: 'I found this cool event on cork.social!',
                    url: getFullUrl(shareURL)
                };

                await navigator.share(shareData);
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Error sharing:', error);
                    window.open(getFullUrl(shareURL), '_blank');
                }
            }
        } else {
            window.open(getFullUrl(shareURL), '_blank');
        }
    };

    return (
        <AnimatedGroup x={xOffset} className="react-bar" opacity={opacity}>
            <Group y={-25} x={85}>
                <Rect
                    width={80}
                    height={20}
                    fill="#f0f0f0"
                    cornerRadius={10}
                    shadowColor="black"
                    shadowBlur={5}
                    shadowOpacity={0.1}
                    shadowOffset={{ x: 1, y: 1 }}
                />
                <Text
                    text="🔗 event link"
                    fontSize={12}
                    fill="#2196f3"
                    width={80}
                    align="center"
                    y={4}
                    onClick={() => window.open(getFullUrl(eventUrl), '_blank')}
                    onTap={() => window.open(getFullUrl(eventUrl), '_blank')}
                />
            </Group>

            <Group y={-25}>
            <Rect
                    width={80}
                    height={20}
                    fill="#f0f0f0"
                    cornerRadius={10}
                    shadowColor="black"
                    shadowBlur={5}
                    shadowOpacity={0.1}
                    shadowOffset={{ x: 1, y: 1 }}
                />
                <Text
                    text="📩 share"
                    fontSize={12}
                    fill="#2196f3"
                    width={80}
                    align="center"
                    y={4}
                    onClick={handleShare}
                    onTap={handleShare}
                />
            </Group>

            <Group y={-25} x={170}>
                <Rect
                    width={80}
                    height={20}
                    fill="#f0f0f0"
                    cornerRadius={10}
                    shadowColor="black"
                    shadowBlur={5}
                    shadowOpacity={0.1}
                    shadowOffset={{ x: 1, y: 1 }}
                />
                <Text
                    text="📍 my area"
                    fontSize={12}
                    fill="#2196f3"
                    width={80}
                    align="center"
                    y={4}
                    onClick={() => window.location.href = '/'}
                    onTap={() => window.location.href = '/'}
                />
            </Group>

            <AnimatedRect
                width={barWidth}
                height={height}
                fill="#f0f0f0"
                cornerRadius={20}
                shadowColor="black"
                shadowBlur={10}
                shadowOpacity={0.2}
                shadowOffset={{ x: 2, y: 2 }}
            />
            {REACTION_EMOJIS.map((letter, index) => (
                <AnimatedGroup
                    key={index}
                    x={index * 60 + 10}
                    y={5}
                    opacity={opacity}
                    onClick={() => handleReactBarClick(index)}
                    onTap={() => handleReactBarClick(index)}
                >
                    <Rect
                        width={50}
                        height={30}
                        fill={selectedIndex === index ? "#e3f2fd" : "white"}
                        cornerRadius={15}
                        shadowColor="black"
                        shadowBlur={5}
                        shadowOpacity={0.1}
                        shadowOffset={{ x: 1, y: 1 }}
                    />
                    <Group x={8} y={5}>
                        <Text
                            text={letter}
                            fontSize={16}
                            x={0}
                        />
                        <Text
                            text={reactionCounts[letter] || 0}
                            fontSize={12}
                            x={20}
                            y={3}
                            fill="#666666"
                        />
                    </Group>
                    {selectedIndex === index && (
                        <Rect
                            width={50}
                            height={2}
                            fill="#2196f3"
                            y={28}
                            cornerRadius={1}
                        />
                    )}
                </AnimatedGroup>
            ))}

            {/* Add particles layer */}
            {particles.map(particle => (
                <ReactBarParticles
                    key={particle.id}
                    letter={particle.letter}
                    startX={particle.startX}
                    startY={particle.startY}
                    angle={particle.angle}
                    speed={particle.speed}
                />
            ))}
        </AnimatedGroup>
    );
};

export default ReactBar;
