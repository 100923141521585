import { REACTION_EMOJIS } from '../../constants/emojis';

export const createExplosion = (index) => {
    const letter = REACTION_EMOJIS[index];
    const particleCount = 8;
    const startX = index * 60 + 35;
    const startY = 0;

    return Array.from({ length: particleCount }).map((_, i) => ({
        id: Date.now() + i,
        letter,
        startX,
        startY,
        angle: (Math.PI * 2 * i) / particleCount - Math.PI / 2,
        speed: 0.5 + Math.random() * 0.5
    }));
}; 
