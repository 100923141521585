import { useState, useEffect } from 'react';
import { Text } from 'react-konva';

export const ReactBarParticles = ({ letter, startX, startY, angle, speed }) => {
    const [pos, setPos] = useState({ x: startX, y: startY, opacity: 1 });

    useEffect(() => {
        let frame;
        let time = 0;

        const animate = () => {
            time += 0.03;
            setPos({
                x: startX + Math.cos(angle) * speed * time * 15,
                y: startY + Math.sin(angle) * speed * time * 15 - (time * time * 20),
                opacity: Math.max(0, 1 - time)
            });

            if (time < 1.0) {
                frame = requestAnimationFrame(animate);
            }
        };

        frame = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(frame);
    }, [startX, startY, angle, speed]);

    return (
        <Text
            text={letter}
            x={pos.x}
            y={pos.y}
            fontSize={12}
            opacity={pos.opacity}
            fill="#666666"
        />
    );
};

// You can add more animation components/utilities here