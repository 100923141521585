import React, {useEffect, useState } from 'react';
import { Text } from 'react-konva';

export const PosterReactParticle = ({ letter, startX, startY }) => {
    const [pos, setPos] = useState({ x: startX, y: startY, opacity: 1 });
  
    useEffect(() => {
      let frame;
      let time = 0;
      
      const animate = () => {
        time += 0.02;
        setPos({
          x: startX + (Math.sin(time * 3) * 20), // Gentle side-to-side motion
          y: startY - (time * 50), // Float upward
          opacity: Math.max(0, 1 - time)
        });
  
        if (time < 1.5) {
          frame = requestAnimationFrame(animate);
        }
      };
  
      frame = requestAnimationFrame(animate);
      return () => cancelAnimationFrame(frame);
    }, [startX, startY]);
  
    return (
      <Text
        text={letter}
        x={pos.x}
        y={pos.y}
        fontSize={36}
        opacity={pos.opacity}
        fill="#2196f3"
      />
    );
  };