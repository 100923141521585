import { useEffect } from 'react';
import { REACTION_EMOJIS } from '../constants/emojis';

export const useWebSocket = () => {
  useEffect(() => {
    console.log('Setting up WebSocket mock and interval');
    
    const ws = {
      send: (msg) => console.log('Sending:', msg),
      close: () => console.log('Closing connection')
    };

    const interval = setInterval(() => {
      //console.log('Interval triggered, creating mock reaction');
      
      const mockReaction = {
        posterId: 'welcomeposter',
        reactionIndex: Math.floor(Math.random() * REACTION_EMOJIS.length),
        timestamp: Date.now(),
      };
      
      //console.log('Dispatching event with reaction:', mockReaction);
      
      const event = new CustomEvent('posterReaction', { 
        detail: mockReaction 
      });
      window.dispatchEvent(event);
    }, 2500);

    return () => {
      console.log('Cleaning up WebSocket mock and interval');
      clearInterval(interval);
      ws.close();
    };
  }, []);

  return { sendReaction: (msg) => console.log('Sending:', msg) };
}; 